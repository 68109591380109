const titles = {
    patientProfileList: "Elenco dei profili pazienti",
    hello: "Ciao",
    patientName: "Nome del paziente",
    patientId: "ID del paziente",
    creationDate: "Data di creazione",
    lastUpdate: "Ultimo aggiornamento",
    status: "Stato",
    action: "Azione",
    profileId: "ID del profilo",
    sizeOfProfilePopulation: "Dimensione della popolazione del profilo",
    numberOfInclusionRules: "Numero di regole di inclusione",
    numberOfRiskGroups: "Numero di gruppi di rischio",
    modelProfileList: "Elenco dei profili del modello",
    view: "Visualizza",
    export: "Esporta",
    import: "Importa",
    completed: "Completato",
    delete: "Elimina",
    generatePrediction: "Genera previsione",
    draft: "Bozza",
    addNewPatient: "Aggiungi nuovo paziente",
    patientProfile: "Profilo del paziente",
    predictionGenerators: "Generatori di previsione",
    readmission: "Ri-ammissione",
    basedOnModels: "Basato su modelli",
    frailtyPredictions: "Previsioni di fragilità",
    predictedIndividualTreatmentEffect: "Effetto del trattamento individuale previsto",
    deleteProfile: "Elimina profilo",
    editProfile: "Modifica profilo",
    dashboard: "Pannello di controllo",
    predictionModel: "Modello di riammissione WP4",
    update: "Aggiorna",
    editPatientProfile: "Modifica profilo paziente",
    generalInformation: "Informazioni generali",
    fullName: "Nome completo",
    countryOfOrigin: "Paese d'origine",
    chf: "CHF",
    copd: "BPCO",
    uti: "ITU",
    emailAddress: "Indirizzo email",
    password: "Password",
    forgotPassword: "Password dimenticata",
    login: "Accedi",
    signInToYourAccount: "Accedi al tuo account",
    welcomeBack: "Benvenuto",
    hospitalization_history: "Storia delle ospedalizzazioni",
    usti: "ITU",
    deteriorated_more_services: "SERVIZI PIÙ DETERIORATI",
    stasis_ulcer: "ULCERA DA STASI",
    skin_tears_or_cut: "LACERAZIONI CUTANEE O TAGLI",
    lessons: "LEZIONI",
    fallany: "CADUTA",
    other_fracture: "ALTRE FRATTURE",
    cancer: "CANCRO",
    infuse: "INFONDI",
    dialysis: "EMODIALISI",
    er_visits_in_the_last_90_days_or_since_last_assessment: "VISITE AL PS NELL'ULTIMO 90 GIORNI O DALLO ULTIMO VALUTAZIONE",
    renal_failure: "INSUFFICIENZA RENALE",
    gender: "GENERE",
    irregular_pulse: "POLSO IRREGOLARE",
    unintended_weight_loss_of_5_percent_or_more_in_last_30_days: "PERDITA DI PESO NON VOLUTA DEL 5(%) O PIÙ NELL'ULTIMO 30 GIORNI",
    ulcer_wound_care_surgical_wound_care: "CURE DELLE ULCERE, ULCERE CHIRURGICHE",
    pressure_ulce: "ULCERA DA PRESSIONE",
    hip_fracture: "FRATTURA DELL'ANCA",
    client_feels_having_a_poor_health: "IL CLIENTE SI SENTE DI AVERE UNA SALUTE CATTIVA",
    experiencing_flare_up_of_recurrent_condition_or_problem: "ESPERIENZA DI RICADUTA DI CONDIZIONE O PROBLEMA RICORRENTE",
    adl_decline: "DECLINO ADL",
    baseline_frailty_score: "PUNTEGGIO DI FRAGILITÀ DI BASE",
    baseline_frailty_subset1_cognition_collapsed: "SOTTOINSIEME 1 DI FRAGILITÀ DI BASE COGNITIVA COLLASSATO",
    baseline_frailty_subset1_clinical_collapsed: "SOTTOINSIEME 1 DI FRAGILITÀ DI BASE CLINICA COLLASSATO",
    short_term_memory: "MEMORIA A BREVE TERMINE",
    worsening_of_decision_making: "PEGGIORAMENTO DELLA PRESA DI DECISIONE",
    alzheimers: "ALZHEIMER",
    dementia_other_than_heart_disease: "DEMENTIA ALTRI CHE MALATTIA CARDIACA",
    parkinsonism: "PARKINSONISMO",
    eating: "MANGIARE",
    tranferring_oneself_from_surfaces: "TRASFERIRSI DA UNA SUPERFICIE ALL'ALTRA",
    bladder_devices_indwelling_urinary_catheter: "DISPOSITIVI VESCICALI CATETERE URINARIO RIMASTO",
    pneumonia: "POLMONITE",
    procedural_memory: "MEMORIA PROCEDURALE",
    fever: "FEbbre",
    diagnosis: "Diagnosi",
    age: "Età",
    unplanned_hospital_or_er_visit: "VISITA OSPEDALIERA O IN PS NON PIANIFICATA",
    number_of_comorbidities: "NUMERO DI COMORBIDITÀ",
    unsteady_gait: "ANDATURA INSTABILE",
    diuretics: "DIURETICI",
    pain_scale: "SCALA DEL DOLORE",
    client_feels_he_she_has_poor_health: "IL CLIENTE SI SENTE DI AVERE UNA SALUTE CATTIVA",
    chess: "SCACCHI",
    adl_status_has_become_worse: "LO STATO ADL È DIVENTATO PEGGIORE",
    security_service: "SERVIZIO DI SICUREZZA",
    visiting_nurses: "INFERMIERE DI VISITA",
    client_went_out_of_the_house_1_day_a_week_or_no_days: "Il cliente è uscito di casa (1 giorno alla settimana o nessun giorno)",
    impaired_vision: "VISIONE ALTERATA",
    caregivers_belief_client_capable: "IL CREDITO DEGLI ASSISTENTI ALLA CAPACITÀ DEL CLIENTE",
    drs: "MEDICI",
    psychotropic_medication: "MEDICAZIONE PSICOTROPICA",
    bmi: "IMC",
    adl_long: "ADL LUNGO",
    cancel: "Annulla",
    saveAndClose: "Salva e chiudi",
    enterToSearch: "Premi Invio per cercare",
    viewModelList: "Visualizza elenco dei modelli",
    chancesHospitalizationInNext12Months: "Possibilità di ospedalizzazione nei prossimi 12 mesi",
    inclusionRules: "Regole di inclusione",
    variableIndex: "Indice variabile",
    variableName: "Nome variabile",
    variableValue: "Valore variabile",
    valueSymetricOddScore: "Valore (Simmetrico) Punteggio dispari",
    oddSymetricScoreSet: "Punteggio simmetrico dispari impostato",
    riskGroups: "Gruppi di rischio",
    threshold: "Soglia",
    dependent_value: "Valore dipendente",
    performance: "Prestazione",
    sample_size: "Dimensione del campione",
    previous_hospitalization_events_in_last_year: "EVENTI DI OSPEDALIZZAZIONE PRECEDENTI NELL'ULTIMO ANNO",
    welcome: "Benvenuto!",
    back: "Indietro",
    frailtyPrediction: "Predizione di fragilità",
    viewModelProfile: "Visualizza profilo del modello",
    users: "Utenti",
    usersList: "Elenco utenti",
    addNewUser: "Aggiungi nuovo utente",
    name: "Nome",
    email: "Email",
    isVerified: "Verificato",
    yes: "Sì",
    no: "No",
    edit: "Modifica",
    resendActivationLink: "Reinvia link di attivazione",
    add: "Aggiungi",
    user: "Utente",
    passwordConfirmation: "Conferma password",
    newPatientProfile: "Nuovo profilo paziente",
    userForm: "Modulo utente",
    modelProfile: "Profilo del modello",
    newModelProfile: "Nuovo profilo del modello",
    locomodation_in_home: "Alloggio a casa",
    meal_preparation: "Preparazione dei pasti",
    stairs: "Scale",
    short_term_memory_ok: "Memoria a breve termine",
    alzheimers_disease: "Malattia di Alzheimer",
    dementia_other_than_alzheimers_disease: "Demensa diversa dalla malattia di Alzheimer",
    paraplegia: "Paraplegia",
    mental_health_delusions: "Allucinazioni di salute mentale",
    mental_health_hallucinations: "Allucinazioni di salute mentale",
    bowel_continence: "Continuità intestinale",
    meal: "Pasto",
    housework: "Lavoro domestico",
    shopping: "Acquisti",
    iadl_alzheimers: "IADL Alzheimer",
    other_dementia: "Altra demenza",
    bath: "Bagno",
    difficulty_meal_preparation: "Difficoltà nella preparazione dei pasti",
    revised_iadl_hierarchy: "gerarchia IADL rivista",
    short_memory_ok: "Memoria a breve termine ok",
    iadl_decline: "Declino IADL",
    iadlModel: "Modello IADL WP4",
    iadlProfileList: "Elenco profili IADL",
    iadlProfile: "Profilo IADL",
    viewIadlModelList: "Visualizza elenco modelli IADL",
    viewIadlModel: "Visualizza modello IADL",
    individualTreatment: "Trattamento individuale",
    seeAll: "Vedi tutto",
    iadlDecline: "HC: Declino IADL",
    hospitalization: "HC: Ospedalizzazione",
    qualityOfLife: "NH: Qualità della vita",
    frailty: "NH: Fragilità",
    viewQualityOfLifeList: "Visualizza elenco qualità della vita",
    viewFrailtyList: "Visualizza elenco fragilità",
    viewFrailty: "Visualizza fragilità",
    qualityOfLifeN: "Qualità della vita",
    frailtyN: "Fragilità",
    baseOnModel: "Basato sul modello",
    item: "Articolo",
    addSuccessfully: "Aggiunto con successo",
    updateSuccessfully: "Aggiornato con successo",
    errorMessage: "Ops, qualcosa è andato storto, riprova",
    questionSubmittedSuccess: "Domanda inviata con successo",
    deletedSuccess: "Eliminato con successo",
    resetPasswordSuccessMsg: "Il link per reimpostare la password è stato inviato al tuo indirizzo email con successo.",
    successLogin: "Accesso eseguito con successo",
    otpSuccessMsg: "OTP inviato al tuo indirizzo email con successo",
    alreadyRegistered: "Già registrato!",
    otpVerifiedMsg: "OTP verificato con successo!",
    otpSentSuccessMsg: "OTP inviato con successo!",
    registerSuccessMsg: "Registrato con successo!",
    tokenInvalidMsg: "Token non valido o scaduto, riprova",
    passwordResetSuccessMsg: "Password reimpostata con successo!",
    yourRequestSubmitSuccessMsg: "La tua richiesta è stata inviata con successo",
    patientImportSuccessMsg: "Importazione paziente riuscita",
    deletedSuccessMsg: "Eliminato con successo",
    accountAlreadyExistsMsg: "Il tuo account è stato registrato con successo, per favore accedi ora!",
    fileUploadSuccessMsg: "File caricato con successo",
    fileDeleteMsg: "File eliminato con successo",
    click: "Clicca",
    here: "Qui",
    postQuestionnaireSentence: "su questo link e compila il modulo per il processo successivo",
    postQuestionnaire: "post-questionario",
    hospitalizationTooltip2: "Probabilità di ricovero in una struttura medica nei prossimi 12 mesi.",
    adlTooltip2: "Probabilità di un peggioramento significativo in IADL nei prossimi 12 mesi.",
    patient: 'Paziente',
    predictionTopic: "Previsioni tra 12 mesi",
    riskOfDeclineOfEvent: "Rischio di declino / evento",
    whatIfYouDecideTo: "Cosa succede se decidi di:",
    stopAntipsychotics: "Interrompere gli antipsicotici",
    startAnticholinergics: "Iniziare gli anticolinergici",
    "startPhysiotherapy": "Inizia o continua la fisioterapia",
    predictedChange: "Cambiamento previsto",
    adl: "ADL",
    iadl: "IADL",
    cps: "CPS",
    falls: "CADUTE",
    configuration: "Configurazione",
    code: "Codice",
    label: "Etichetta",
    index: "Indice",
    wp4: "WP4",
    configurations: "Configurazioni",
    configurationWp4Form: "Modulo di configurazione WP4",
    configurationWp4SectionForm: "Modulo di configurazione della sezione WP4",
    wp4Section: "Sezione WP4",
    title: "Titolo",
    section: "Sezione",
    isEmailVerified: "L'email è verificata?",
    areYourSureWantToLogout: "Sei sicuro di voler uscire?",
    postQuestion: "per il questionario decisionale.",
    toProvideFeedback: "per fornire un feedback.",
    logout: "Disconnettersi",
    clone: "Clona",
    cloneSuccessMsg: "Dati del paziente clonati con successo",
    codeSystem: "Sistema di codici",
    importExternalPatient: "Importa paziente esterno",
    addPatient: "Aggiungi paziente",
    patientList: "Elenco pazienti",
    patientForm: "Modulo paziente",
    save: "Salva",
    link1: "Link1",
    link2: "Link2",
    patientFeedbackLink: "Link per feedback del paziente",
    forDecisionQuestionnaire: "per il questionario decisionale",
    link: "Link",
    showRaiData: "Mostra dati RAI",
    startPhysiotherapyContent: "tempo: fino a 6 mesi; Per i ricoveri e le cadute è stato utilizzato un periodo di osservazione retrospettivo di 90 giorni durante il follow-up. Definizione di fisioterapia: almeno 4 sedute a settimana, ciascuna seduta della durata minima di 15 minuti, ad eccezione della Scala delle prestazioni cognitive (CPS), dove è di 5 giorni",
    startPhysiotherapyContent1: "L'effetto del trattamento individuale (ITE) è la differenza tra l'effetto previsto in trattamento e l'effetto previsto se il paziente non riceve il trattamento. Un effetto previsto inferiore a 0 indica un minore declino, nessun declino o un miglioramento maggiore con la terapia rispetto a nessuna terapia. Un effetto previsto superiore a 0 indica un maggiore declino con la terapia rispetto a nessuna terapia",
    startPhysiotherapyContent2: "L'effetto del trattamento individuale (ITE) è la differenza tra l'effetto previsto in trattamento e l'effetto previsto se il paziente non riceve il trattamento. Un effetto previsto superiore a 0 indica un minore declino, nessun declino o un miglioramento maggiore con la terapia rispetto a nessuna terapia. Un effetto previsto inferiore a 0 indica un maggiore declino con la terapia rispetto a nessuna terapia",
    stopAntipsychoticsContent1: "L'effetto del trattamento individuale (ITE) è la differenza tra l'effetto previsto dopo la sospensione del farmaco antipsicotico e l'effetto previsto se il paziente continua ad assumere il farmaco. Un effetto previsto inferiore a 0 indica un minore declino, nessun declino o un miglioramento maggiore del CHESS con la sospensione del farmaco antipsicotico rispetto alla continuazione del farmaco. Un effetto previsto superiore a 0 indica un maggiore declino del CHESS quando si interrompe il farmaco antipsicotico rispetto a quando si continua il farmaco. Lasso di tempo: fino a 12 mesi",
    stopAntipsychoticsContentAdl: "L'effetto del trattamento individuale (ITE) è la differenza tra l'effetto previsto dopo la sospensione del farmaco antipsicotico e l'effetto previsto se il paziente continua ad assumere il farmaco. Un effetto previsto inferiore a 0 indica un minore declino, nessun declino o un miglioramento maggiore dell'ADL con la sospensione del farmaco antipsicotico rispetto alla continuazione del farmaco. Un effetto previsto superiore a 0 indica un maggiore declino dell'ADL quando si interrompe il farmaco antipsicotico rispetto a quando si continua il farmaco. Lasso di tempo: fino a 12 mesi",
    stopAntipsychoticsContentCps: "L'effetto del trattamento individuale (ITE) è la differenza tra l'effetto previsto dopo la sospensione del farmaco antipsicotico e l'effetto previsto se il paziente continua ad assumere il farmaco. Un effetto previsto inferiore a 0 indica un minore declino, nessun declino o un miglioramento maggiore del CPS con la sospensione del farmaco antipsicotico rispetto alla continuazione del farmaco. Un effetto previsto superiore a 0 indica un maggiore declino del CPS quando si interrompe il farmaco antipsicotico rispetto a quando si continua il farmaco. Lasso di tempo: fino a 12 mesi",
    stopAntipsychoticsContentHospitalization: "L'effetto del trattamento individuale (ITE) è la differenza tra l'effetto previsto dopo la sospensione del farmaco antipsicotico e l'effetto previsto se il paziente continua ad assumere il farmaco. Un effetto previsto inferiore a 0 indica una minore probabilità di ospedalizzazione con la sospensione del farmaco antipsicotico rispetto alla continuazione del farmaco. Un effetto previsto superiore a 0 indica una maggiore probabilità di ospedalizzazione quando si interrompe il farmaco antipsicotico rispetto a quando si continua il farmaco. Lasso di tempo: fino a 12 mesi",
    startAnticholinergicsAdl: "L'effetto del trattamento individuale (ITE) è la differenza tra l'effetto previsto dopo l'inizio della terapia anticolinergica e l'effetto previsto se il paziente non riceve il farmaco. Un effetto previsto inferiore a 0 indica un minore declino, nessun declino o un miglioramento maggiore dell'ADL dopo l'inizio del trattamento rispetto all'assenza di trattamento. Un effetto previsto superiore a 0 indica un maggiore declino dell'ADL con il trattamento rispetto all'assenza di trattamento. Lasso di tempo: fino a 12 mesi",
    startAnticholinergicsCps: "L'effetto del trattamento individuale (ITE) è la differenza tra l'effetto previsto dopo l'inizio della terapia anticolinergica e l'effetto previsto se il paziente non riceve il farmaco. Un effetto previsto inferiore a 0 indica un minore declino, nessun declino o un miglioramento maggiore del CPS dopo l'inizio del trattamento rispetto all'assenza di trattamento. Un effetto previsto superiore a 0 indica un maggiore declino del CPS con il trattamento rispetto all'assenza di trattamento. Lasso di tempo: fino a 12 mesi",
    startAnticholinergicsHospitalization: "L'effetto del trattamento individuale (ITE) è la differenza tra l'effetto previsto dopo l'inizio della terapia anticolinergica e l'effetto previsto se il paziente non riceve il farmaco. Un effetto previsto inferiore a 0 indica una minore probabilità di ospedalizzazione dopo l'inizio del trattamento rispetto all'assenza di trattamento. Un effetto previsto superiore a 0 indica una maggiore probabilità di ospedalizzazione con il trattamento rispetto all'assenza di trattamento. Lasso di tempo: fino a 12 mesi",
    startAnticholinergicsFalls: "L'effetto del trattamento individuale (ITE) è la differenza tra l'effetto previsto dopo l'inizio della terapia anticolinergica e l'effetto previsto se il paziente non riceve il farmaco. Un effetto previsto inferiore a 0 indica una minore probabilità di cadute dopo l'inizio del trattamento rispetto all'assenza di trattamento. Un effetto previsto superiore a 0 indica una maggiore probabilità di cadute con il trattamento rispetto all'assenza di trattamento. Lasso di tempo: periodo di osservazione retrospettivo di 90 giorni dopo 12 mesi",
    questionnairePopupHeading: "Dichiarazione generale per tutte le previsioni",
    questionnairePopupHeadingList1: "Si prega di prestare attenzione nelle decisioni e di ponderare la propria esperienza clinica nella decisione finale, poiché l'accuratezza dei modelli varia tra i destinatari delle cure.",
    questionnairePopupHeadingList2: "Queste previsioni sono state sviluppate e validate dal consorzio I-CARE4OLD",
    questionnairePopupHeadingList3: "Le previsioni sono state informate da analisi avanzate, incluso il machine learning. Abbiamo applicato la migliore scienza su dati di routine di alta qualità provenienti da più paesi.",
    questionnairePopupHeadingList4: "Le validazioni sono state effettuate su campioni molto ampi di dati longitudinali di alta qualità su destinatari di cure reali in assistenza domiciliare o case di riposo in Belgio, Canada, Finlandia, Hong Kong, Italia, Nuova Zelanda, Paesi Bassi e USA.",
    questionnairePopupHeadingList5: "Maggiori informazioni: www.icare4old.eu / Testo guida",
    close: "Chiudi",
    description: "Descrizione",
    wp5: "Wp5",
    wp6: "Wp6",
    wp6Prediction: "Previsione Wp6",
    configurationWp5Form: "Configurazione modulo Wp5",
    configurationWp6Form: "Configurazione modulo Wp6",
    boxType: "Tipo di scatola",
    addNewSection: "Aggiungi nuova sezione",
    translation: "Traduzione",
    upload: "Carica",
    noRecordFound: "Nessun record trovato",
    attachments: "Allegati",
    questionnairePopupHeadingList6: "su questo link e compila il modulo per il processo successivo",
    preQuestionnaire: "pre-questionario",
    homeCare: "Assistenza domiciliare",
    nursingHome: "Casa di riposo",
    afterGeneratePredictionPopup: "Ti sei familiarizzato con il profilo del paziente?",
    thankYou: "Grazie!",
    predictionIn12Months: "Ennusteet 12 kuukauden aikana",
    functionWorsening: "Toimintakyvyn heikkeneminen (ADL + IADL)",
    cognitionWorsening: "Kognition heikkeneminen (CPS)",
    healthInstability: "Terveydentilan epävakaus (CHESS)",
    otherFalls: "Muut (kaatumiset)",
    qualityOfLifeWorsening: "Elämänlaadun heikkeneminen (HUI3)",
    "riskOfDecline": "Rischio di evento",
    whatIfYouDecide: "Mitä jos päätät:",
    probabilityOfHospitalization: "Todennäköisyys joutua sairaalaan seuraavien 12 kuukauden aikana.",
    probabilityOfWorseningIADL: "Todennäköisyys merkittävään heikkenemiseen IADL:ssa seuraavien 12 kuukauden aikana.",
    otherFallsInfoBox: "L'effetto del trattamento individuale (ITE) è la differenza tra l'effetto previsto dopo l'inizio del trattamento anticolinergico e l'effetto previsto se il paziente non riceve il farmaco. Un effetto previsto inferiore a 0 indica una minore probabilità di cadute dopo l'inizio del farmaco rispetto a nessun farmaco. Un effetto previsto superiore a 0 indica una maggiore probabilità di cadute con il farmaco rispetto a nessun farmaco. Periodo di tempo: un periodo di revisione retrospettiva di 90 giorni dopo 12 mesi.",
    lastAllInfoBox: "L'effetto del trattamento individuale (ITE) è la differenza tra l'effetto previsto con il trattamento e l'effetto previsto se il paziente non riceve il trattamento. Un effetto previsto inferiore a 0 indica un minor declino, nessun declino o un miglioramento maggiore con la terapia rispetto a nessuna terapia. Un effetto previsto superiore a 0 indica un declino maggiore con la terapia rispetto a nessuna terapia."
};
export default {
    locale: "it-IT",
    messages: {
        title: titles,
    },
};
