const titles = {
    patientProfileList: "Lista profilów pacjentów",
    hello: "Cześć",
    patientName: "Imię pacjenta",
    patientId: "Identyfikator pacjenta",
    creationDate: "Data utworzenia",
    lastUpdate: "Ostatnia aktualizacja",
    status: "Status",
    action: "Akcja",
    profileId: "Identyfikator profilu",
    sizeOfProfilePopulation: "Rozmiar populacji profilu",
    numberOfInclusionRules: "Liczba reguł włączenia",
    numberOfRiskGroups: "Liczba grup ryzyka",
    modelProfileList: "Lista profilów modeli",
    view: "Wyświetl",
    export: "Eksport",
    import: "Import",
    completed: "Ukończony",
    delete: "Usuń",
    generatePrediction: "Generuj prognozę",
    draft: "Szkic",
    addNewPatient: "Dodaj nowego pacjenta",
    patientProfile: "Profil pacjenta",
    predictionGenerators: "Generatory prognoz",
    readMission: "Powrót",
    basedOnModels: "Oparte na modelach",
    frailtyPredictions: "Prognozy wrażliwości",
    predictedIndividualTreatmentEffect: "Przewidywany efekt leczenia indywidualnego",
    deleteProfile: "Usuń profil",
    editProfile: "Edytuj profil",
    dashboard: "Pulpit",
    predictionModel: "Model readmisji WP4",
    update: "Aktualizuj",
    editPatientProfile: "Edytuj profil pacjenta",
    generalInformation: "Informacje ogólne",
    fullName: "Imię i nazwisko",
    countryOfOrigin: "Kraj pochodzenia",
    chf: "Niewydolność serca",
    copd: "POChP",
    uti: "Zapalenie dróg moczowych",
    emailAddress: "Adres email",
    password: "Hasło",
    forgotPassword: "Zapomniałeś hasła",
    login: "Zaloguj się",
    signInToYourAccount: "Zaloguj się na swoje konto",
    welcomeBack: "Witaj ponownie",
    hospitalization_history: "Historia hospitalizacji",
    usti: "Zapalenie dróg moczowych",
    deteriorated_more_services: "Pogorszenie usług",
    stasis_ulcer: "Owrzodzenie żylne",
    skin_tears_or_cut: "Rozdarcia skóry lub cięcia",
    lessons: "Lekcje",
    fallany: "Upadek",
    other_fracture: "Inne złamanie",
    cancer: "Rak",
    infuse: "Infuzja",
    dialysis: "Dializa",
    er_visits_in_the_last_90_days_or_since_last_assessment: "Wizyty na oddziale ratunkowym w ciągu ostatnich 90 dni lub od ostatniej oceny",
    renal_failure: "Niewydolność nerek",
    gender: "Płeć",
    irregular_pulse: "Nieregularne tętno",
    unintended_weight_loss_of_5_percent_or_more_in_last_30_days: "Nieumyślna utrata wagi o 5% lub więcej w ciągu ostatnich 30 dni",
    ulcer_wound_care_surgical_wound_care: "Leczenie owrzodzeń skóry/ran pooperacyjnych",
    pressure_ulce: "Owrzodzenie ciśnieniowe",
    hip_fracture: "Złamanie biodra",
    client_feels_having_a_poor_health: "Pacjent odczuwa złe zdrowie",
    experiencing_flare_up_of_recurrent_condition_or_problem: "Doświadczanie nasilenia nawrotu stanu lub problemu",
    adl_decline: "Spadek zdolności do codziennych czynności życiowych (ADL)",
    baseline_frailty_score: "Wyjściowy wskaźnik osłabienia",
    baseline_frailty_subset1_cognition_collapsed: "Skumulowany podzbiór 1 oceny kognitywnej osłabienia wyjściowego",
    baseline_frailty_subset1_clinical_collapsed: "Skumulowany podzbiór 1 oceny klinicznej osłabienia wyjściowego",
    short_term_memory: "Pamięć krótkotrwała",
    worsening_of_decision_making: "Zaostrzenie procesu podejmowania decyzji",
    alzheimers: "Alzheimer",
    dementia_other_than_heart_disease: "Otępienie inne niż choroba serca",
    parkinsonism: "Parkinsonizm",
    eating: "Jedzenie",
    tranferring_oneself_from_surfaces: "PRZENOSZENIE SIĘ Z POWIERZCHNI",
    bladder_devices_indwelling_urinary_catheter: "WKŁADKI PUPELNE",
    pneumonia: "ZAPALENIE PŁUC",
    procedural_memory: "PAMIĘĆ PROCEDURALNA",
    fever: "GORĄCZKA",
    diagnosis: "Diagnoza",
    age: "Wiek",
    unplanned_hospital_or_er_visit: "NIEZAPLANOWANA WIZYTA W SZPITALU LUB ODDZIALE RATUNKOWYM",
    number_of_comorbidities: "LICZBA CHORÓB WSPÓŁISTNIEJĄCYCH",
    unsteady_gait: "NIEPRAWIDŁOWY CHÓD",
    diuretics: "DIURETYKI",
    pain_scale: "SKALA BÓLU",
    client_feels_he_she_has_poor_health: "KLIENT ODCZUWA ZŁE ZDROWIE",
    chess: "SZACHY",
    adl_status_has_become_worse: "STATUS ADL ULEGŁ ZAGORSZENIU",
    security_service: "USŁUGI BEZPIECZEŃSTWA",
    visiting_nurses: "ODWIEDZAJĄCE PIELĘGNIARKI",
    client_went_out_of_the_house_1_day_a_week_or_no_days: "KLIENT WYCHODZIŁ Z DOMU (1 DZIEŃ W TYGODNIU LUB ŻADEN DZIEŃ)",
    impaired_vision: "UPORAJĄCY SIĘ Z WIDZENIEM",
    caregivers_belief_client_capable: "PRZEKONANIE OPIEKUNÓW O MOŻLIWOŚCIACH KLIENTA",
    drs: "LEKARZ",
    psychotropic_medication: "LEKI PSYCHOTROPICZNE",
    bmi: "BMI",
    adl_long: "ADL DŁUGIE",
    cancel: "Anuluj",
    saveAndClose: "Zapisz i Zamknij",
    enterToSearch: "Wpisz, aby wyszukać",
    viewModelList: "Lista modeli",
    chancesHospitalizationInNext12Months: "Szansa na hospitalizację w ciągu najbliższych 12 miesięcy",
    inclusionRules: "Reguły włączenia",
    variableIndex: "Indeks zmiennej",
    variableName: "Nazwa zmiennej",
    variableValue: "Wartość zmiennej",
    valueSymetricOddScore: "Wartość (Symetric) Odd Score",
    oddSymetricScoreSet: "Nieparzysty zestaw wyników symetrycznych",
    riskGroups: "Grupy ryzyka",
    threshold: "Prog",
    dependent_value: "Wartość zależna",
    performance: "Wydajność",
    sample_size: "Wielkość próbki",
    previous_hospitalization_events_in_last_year: "Poprzednie wydarzenia szpitalne w ostatnim roku",
    welcome: "Witaj!",
    back: "Powrót",
    frailtyPrediction: "Prognoza wrażliwości",
    viewModelProfile: "Profil modelu",
    users: "Użytkownicy",
    usersList: "Lista użytkowników",
    addNewUser: "Dodaj nowego użytkownika",
    name: "Imię",
    email: "Email",
    isVerified: "Zweryfikowany",
    yes: "Tak",
    no: "Nie",
    edit: "Edytuj",
    resendActivationLink: "Wyślij ponownie link aktywacyjny",
    add: "Dodaj",
    user: "Użytkownik",
    passwordConfirmation: "Potwierdzenie hasła",
    newPatientProfile: "Nowy profil pacjenta",
    userForm: "Formularz użytkownika",
    modelProfile: "Profil modelu",
    newModelProfile: "Nowy profil modelu",
    locomodation_in_home: "Zakwaterowanie w domu",
    meal_preparation: "Przygotowanie posiłków",
    stairs: "Schody",
    short_term_memory_ok: "Pamięć krótkotrwała",
    alzheimers_disease: "Choroba Alzheimera",
    dementia_other_than_alzheimers_disease: "Otępienie inne niż choroba Alzheimera",
    paraplegia: "Paraplegia",
    mental_health_delusions: "Urojenia zdrowia psychicznego",
    mental_health_hallucinations: "Halucynacje zdrowia psychicznego",
    bowel_continence: "Kontynencja jelit",
    meal: "Posiłek",
    housework: "Prace domowe",
    shopping: "Zakupy",
    iadl_alzheimers: "IADL Alzheimera",
    other_dementia: "Inne demencje",
    bath: "Kąpiel",
    difficulty_meal_preparation: "Trudności w przygotowaniu posiłków",
    revised_iadl_hierarchy: "zrewidowana hierarchia IADL",
    short_memory_ok: "Krótkotrwała pamięć ok",
    iadl_decline: "Spadek IADL",
    iadlModel: "Model IADL",
    iadlProfileList: "Lista profili IADL",
    iadlProfile: "Model IADL WP4",
    viewIadlModelList: "Wyświetl listę modeli IADL",
    viewIadlModel: "Wyświetl model IADL",
    individualTreatment: "Indywidualne leczenie",
    seeAll: "Zobacz wszystko",
    iadlDecline: "HC: Spadek IADL",
    hospitalization: "HC: Hospitalizacja",
    qualityOfLife: "NH: Jakość życia",
    frailty: "NH: Kruchość",
    viewQualityOfLifeList: "Bekijk kwaliteitslijst van leven",
    viewFrailtyList: "Bekijk kwetsbaarheid lijst",
    viewFrailty: "Bekijk kwetsbaarheid",
    qualityOfLifeN: "Jakość życia",
    frailtyN: "Kruchość",
    baseOnModel: "Na podstawie modelu",
    item: "Przedmiot",
    addSuccessfully: "Dodano pomyślnie",
    updateSuccessfully: "Zaktualizowano pomyślnie",
    errorMessage: "Ops, coś poszło nie tak, spróbuj ponownie",
    questionSubmittedSuccess: "Pytanie przesłane pomyślnie",
    deletedSuccess: "Usunięto pomyślnie",
    resetPasswordSuccessMsg: "Link do resetowania hasła został pomyślnie wysłany na Twój e-mail.",
    successLogin: "Zalogowano pomyślnie",
    otpSuccessMsg: "OTP wysłano pomyślnie na Twój e-mail",
    alreadyRegistered: "Już zarejestrowany!",
    otpVerifiedMsg: "OTP zweryfikowano pomyślnie!",
    otpSentSuccessMsg: "OTP wysłano pomyślnie!",
    registerSuccessMsg: "Zarejestrowano pomyślnie!",
    tokenInvalidMsg: "Token jest nieprawidłowy lub wygasł, spróbuj ponownie",
    passwordResetSuccessMsg: "Hasło zresetowano pomyślnie!",
    yourRequestSubmitSuccessMsg: "Twoje zgłoszenie zostało pomyślnie przesłane",
    patientImportSuccessMsg: "Import pacjenta zakończony sukcesem",
    deletedSuccessMsg: "Usunięto pomyślnie",
    accountAlreadyExistsMsg: "Twoje konto zostało pomyślnie zarejestrowane, zaloguj się teraz!",
    fileUploadSuccessMsg: "Plik został pomyślnie przesłany",
    fileDeleteMsg: "Plik został pomyślnie usunięty",
    click: "Kliknij",
    here: "Tutaj",
    postQuestionnaireSentence: "w tym linku i wypełnij formularz, aby kontynuować proces",
    postQuestionnaire: "post-kwestionariusz",
    hospitalizationTooltip2: "Prawdopodobieństwo hospitalizacji w placówce medycznej w ciągu najbliższych 12 miesięcy.",
    adlTooltip2: "Prawdopodobieństwo znacznego pogorszenia się w IADL w ciągu najbliższych 12 miesięcy.",
    patient: "Pacjent",
    predictionTopic: "Prognozy na 12 miesięcy",
    riskOfDeclineOfEvent: "Ryzyko spadku / zdarzenia",
    whatIfYouDecideTo: "Co jeśli zdecydujesz się na:",
    stopAntipsychotics: "Zatrzymać leki przeciwpsychotyczne",
    startAnticholinergics: "Zacząć stosować leki przeciwcholinergiczne",
    "startPhysiotherapy": "Rozpocznij lub kontynuuj fizjoterapię",
    predictedChange: "Przewidywana zmiana",
    adl: "ADL",
    iadl: "IADL",
    cps: "CPS",
    falls: "UPADKI",
    configuration: "Konfiguracja",
    code: "Kod",
    label: "Etykieta",
    index: "Indeks",
    wp4: "WP4",
    configurations: "Konfiguracje",
    configurationWp4Form: "Formularz konfiguracji WP4",
    configurationWp4SectionForm: "Formularz sekcji konfiguracji WP4",
    wp4Section: "Sekcja WP4",
    title: "Tytuł",
    section: "Sekcja",
    isEmailVerified: "Czy e-mail jest zweryfikowany?",
    areYourSureWantToLogout: "Czy na pewno chcesz się wylogować?",
    postQuestion: "dla kwestionariusza decyzyjnego.",
    toProvideFeedback: "aby przekazać opinię.",
    logout: "Wyloguj się",
    clone: "Klonuj",
    cloneSuccessMsg: "Dane pacjenta zostały pomyślnie sklonowane",
    codeSystem: "System kodów",
    importExternalPatient: "Importuj zewnętrznego pacjenta",
    addPatient: "Dodaj pacjenta",
    patientList: "Lista pacjentów",
    patientForm: "Formularz pacjenta",
    save: "Zapisz",
    link1: "Link1",
    link2: "Link2",
    patientFeedbackLink: "Link do opinii pacjenta",
    forDecisionQuestionnaire: "dla kwestionariusza decyzyjnego",
    link: "Link",
    showRaiData: "Pokaż dane RAI",
    startPhysiotherapyContent: "ramy czasowe: do 6 miesięcy; W przypadku hospitalizacji i upadków użyto okresu retrospektywnego wynoszącego 90 dni przy monitorowaniu. definicja PT: co najmniej 4 sesje tygodniowo, każda sesja trwa minimum 15 minut, z wyjątkiem Cognitive Performance Scale (CPS), gdzie wynosi 5 dni",
    startPhysiotherapyContent1: "Indywidualny efekt leczenia (ITE) to różnica między przewidywanym efektem podczas leczenia a przewidywanym efektem, jeśli pacjent nie otrzyma leczenia. Przewidywany efekt niższy niż 0 wskazuje na mniejszy spadek, brak spadku lub większą poprawę w terapii w porównaniu z brakiem terapii. Przewidywany efekt wyższy niż 0 wskazuje na większy spadek w terapii w porównaniu z brakiem terapii",
    startPhysiotherapyContent2: "Indywidualny efekt leczenia (ITE) to różnica między przewidywanym efektem podczas leczenia a przewidywanym efektem, jeśli pacjent nie otrzyma leczenia. Przewidywany efekt wyższy niż 0 wskazuje na mniejszy spadek, brak spadku lub większą poprawę w terapii w porównaniu z brakiem terapii. Przewidywany efekt niższy niż 0 wskazuje na większy spadek w terapii w porównaniu z brakiem terapii",
    stopAntipsychoticsContent1: "Indywidualny efekt leczenia (ITE) to różnica między przewidywanym efektem po odstawieniu leków przeciwpsychotycznych a przewidywanym efektem kontynuacji leczenia. Przewidywany efekt niższy niż 0 wskazuje na mniejszy spadek, brak spadku lub większą poprawę CHESS po odstawieniu leków przeciwpsychotycznych w porównaniu z kontynuacją leczenia. Przewidywany efekt wyższy niż 0 wskazuje na większy spadek CHESS po odstawieniu leków przeciwpsychotycznych w porównaniu z kontynuacją leczenia. Ramy czasowe: do 12 miesięcy",
    stopAntipsychoticsContentAdl: "Indywidualny efekt leczenia (ITE) to różnica między przewidywanym efektem po odstawieniu leków przeciwpsychotycznych a przewidywanym efektem kontynuacji leczenia. Przewidywany efekt niższy niż 0 wskazuje na mniejszy spadek, brak spadku lub większą poprawę ADL po odstawieniu leków przeciwpsychotycznych w porównaniu z kontynuacją leczenia. Przewidywany efekt wyższy niż 0 wskazuje na większy spadek ADL po odstawieniu leków przeciwpsychotycznych w porównaniu z kontynuacją leczenia. Ramy czasowe: do 12 miesięcy",
    stopAntipsychoticsContentCps: "Indywidualny efekt leczenia (ITE) to różnica między przewidywanym efektem po odstawieniu leków przeciwpsychotycznych a przewidywanym efektem kontynuacji leczenia. Przewidywany efekt niższy niż 0 wskazuje na mniejszy spadek, brak spadku lub większą poprawę CPS po odstawieniu leków przeciwpsychotycznych w porównaniu z kontynuacją leczenia. Przewidywany efekt wyższy niż 0 wskazuje na większy spadek CPS po odstawieniu leków przeciwpsychotycznych w porównaniu z kontynuacją leczenia. Ramy czasowe: do 12 miesięcy",
    stopAntipsychoticsContentHospitalization: "Indywidualny efekt leczenia (ITE) to różnica między przewidywanym efektem po odstawieniu leków przeciwpsychotycznych a przewidywanym efektem kontynuacji leczenia. Przewidywany efekt niższy niż 0 wskazuje na niższe prawdopodobieństwo hospitalizacji po odstawieniu leków przeciwpsychotycznych w porównaniu z kontynuacją leczenia. Przewidywany efekt wyższy niż 0 wskazuje na wyższe prawdopodobieństwo hospitalizacji po odstawieniu leków przeciwpsychotycznych w porównaniu z kontynuacją leczenia. Ramy czasowe: do 12 miesięcy",
    startAnticholinergicsAdl: "Indywidualny efekt leczenia (ITE) to różnica między przewidywanym efektem po rozpoczęciu stosowania leków cholinolitycznych a przewidywanym efektem, jeśli pacjent nie otrzyma leczenia. Przewidywany efekt niższy niż 0 wskazuje na mniejszy spadek, brak spadku lub większą poprawę ADL po rozpoczęciu leczenia w porównaniu z brakiem leczenia. Przewidywany efekt wyższy niż 0 wskazuje na większy spadek ADL po leczeniu w porównaniu z brakiem leczenia. Ramy czasowe: do 12 miesięcy",
    startAnticholinergicsCps: "Indywidualny efekt leczenia (ITE) to różnica między przewidywanym efektem po rozpoczęciu stosowania leków cholinolitycznych a przewidywanym efektem, jeśli pacjent nie otrzyma leczenia. Przewidywany efekt niższy niż 0 wskazuje na mniejszy spadek, brak spadku lub większą poprawę CPS po rozpoczęciu leczenia w porównaniu z brakiem leczenia. Przewidywany efekt wyższy niż 0 wskazuje na większy spadek CPS po leczeniu w porównaniu z brakiem leczenia. Ramy czasowe: do 12 miesięcy",
    startAnticholinergicsHospitalization: "Indywidualny efekt leczenia (ITE) to różnica między przewidywanym efektem po rozpoczęciu stosowania leków cholinolitycznych a przewidywanym efektem, jeśli pacjent nie otrzyma leczenia. Przewidywany efekt niższy niż 0 wskazuje na niższe prawdopodobieństwo hospitalizacji po rozpoczęciu leczenia w porównaniu z brakiem leczenia. Przewidywany efekt wyższy niż 0 wskazuje na wyższe prawdopodobieństwo hospitalizacji po leczeniu w porównaniu z brakiem leczenia. Ramy czasowe: do 12 miesięcy",
    startAnticholinergicsFalls: "Indywidualny efekt leczenia (ITE) to różnica między przewidywanym efektem po rozpoczęciu stosowania leków cholinolitycznych a przewidywanym efektem, jeśli pacjent nie otrzyma leczenia. Przewidywany efekt niższy niż 0 wskazuje na niższe prawdopodobieństwo upadków po rozpoczęciu leczenia w porównaniu z brakiem leczenia. Przewidywany efekt wyższy niż 0 wskazuje na wyższe prawdopodobieństwo upadków po leczeniu w porównaniu z brakiem leczenia. Ramy czasowe: okres retrospektywny wynoszący 90 dni po 12 miesiącach",
    questionnairePopupHeading: "Ogólne zastrzeżenie do wszystkich prognoz",
    questionnairePopupHeadingList1: "Proszę zachować ostrożność przy podejmowaniu decyzji i uwzględnić swoją wiedzę kliniczną przy ostatecznym wyborze, ponieważ dokładność modeli różni się w zależności od odbiorców opieki.",
    questionnairePopupHeadingList2: "Te prognozy zostały opracowane i zweryfikowane przez konsorcjum I-CARE4OLD",
    questionnairePopupHeadingList3: "Prognozy opierają się na zaawansowanych analizach, w tym uczeniu maszynowym. Zastosowaliśmy najlepsze nauki na wysokiej jakości danych rutynowej opieki z różnych krajów.",
    questionnairePopupHeadingList4: "Walidacje zostały przeprowadzone na bardzo dużych próbach wysokiej jakości danych longitudinalnych dotyczących rzeczywistych odbiorców opieki w opiece domowej lub domach opieki w Belgii, Kanadzie, Finlandii, Hongkongu, Włoszech, Nowej Zelandii, Holandii i USA.",
    questionnairePopupHeadingList5: "Więcej informacji: www.icare4old.eu / Tekst wytycznych",
    close: "Zamknij",
    description: "Opis",
    wp5: "Wp5",
    wp6: "Wp6",
    wp6Prediction: "Prognoza Wp6",
    configurationWp5Form: "Konfiguracja formularza Wp5",
    configurationWp6Form: "Konfiguracja formularza Wp6",
    boxType: "Rodzaj pudełka",
    addNewSection: "Dodaj nową sekcję",
    translation: "Tłumaczenie",
    upload: "Prześlij",
    noRecordFound: "Brak wyników",
    attachments: "Załączniki",
    questionnairePopupHeadingList6: "na tym linku i wypełnij formularz do dalszego procesu",
    preQuestionnaire: "przed-kwestionariusz",
    homeCare: "Opieka domowa",
    nursingHome: "Dom opieki",
    afterGeneratePredictionPopup: "Zapoznałeś się z profilem pacjenta?",
    thankYou: "Dziękuję!",
    predictionIn12Months: "Prognozy na 12 miesięcy",
    functionWorsening: "Pogorszenie funkcji (ADL + IADL)",
    cognitionWorsening: "Pogorszenie zdolności poznawczych (CPS)",
    healthInstability: "Niestabilność zdrowia (CHESS)",
    otherFalls: "Inne (upadki)",
    qualityOfLifeWorsening: "Pogorszenie jakości życia (HUI3)",
    "riskOfDecline": "Ryzyko zdarzenia",
    whatIfYouDecide: "Co jeśli zdecydujesz się:",
    probabilityOfHospitalization: "Prawdopodobieństwo hospitalizacji w placówce medycznej w ciągu następnych 12 miesięcy.",
    probabilityOfWorseningIADL: "Prawdopodobieństwo znacznego pogorszenia się funkcji IADL w ciągu następnych 12 miesięcy.",
    otherFallsInfoBox: "Indywidualny efekt leczenia (ITE) to różnica między przewidywanym efektem po rozpoczęciu stosowania leków antycholinergicznych a przewidywanym efektem, jeśli pacjent nie otrzyma leków. Przewidywany efekt niższy niż 0 oznacza mniejsze prawdopodobieństwo upadków po rozpoczęciu leczenia w porównaniu z brakiem leków. Wyższy efekt oznacza większe prawdopodobieństwo upadków z lekami w porównaniu do braku leków. Okres: okres retrospektywny 90 dni po 12 miesiącach.",
    lastAllInfoBox: "Indywidualny efekt leczenia (ITE) to różnica między przewidywanym efektem pod leczeniem a przewidywanym efektem, jeśli pacjent nie otrzyma leczenia. Efekt przewidywany poniżej 0 oznacza mniejszy spadek, brak spadku lub większą poprawę w terapii w porównaniu z brakiem terapii. Wyższy efekt przewidywany oznacza większy spadek w terapii w porównaniu z brakiem terapii."
};

export default {
    locale: "pl-PL",
    messages: {
        title: titles
    },
};
