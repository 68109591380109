const titles = {
    patientProfileList: "Asiakasprofiilit",
    hello: "Hei",
    patientName: "Asiakkaan nimi",
    patientId: "Asiakkaan ID",
    creationDate: "Luontipäivä",
    lastUpdate: "Viimeisin päivitys",
    status: "Asiakastietojen status",
    action: "Toiminta",
    profileId: "Profiilin tunnus",
    sizeOfProfilePopulation: "Profiilipopulaation koko",
    numberOfInclusionRules: "Sisällytysrajojen lukumäärä",
    numberOfRiskGroups: "Riskiryhmien lukumäärä",
    modelProfileList: "Malliprofiililuettelo",
    view: "Näytä",
    export: "Vie",
    import: "Tuo",
    completed: "Valmis",
    delete: "Poista",
    generatePrediction: "Luo ennuste",
    draft: "Luonnos",
    addNewPatient: "Lisää uusi potilas",
    patientProfile: "Potilasprofiili",
    predictionGenerators: "Ennustegeneraattorit",
    readmission: "Uudelleenottaminen",
    basedOnModels: "Pohjautuu malleihin",
    frailtyPredictions: "Haurausennusteet",
    predictedIndividualTreatmentEffect: "Ennustettu yksilöllinen hoitovaikutus",
    deleteProfile: "Poista profiili",
    editProfile: "Muokkaa profiilia",
    dashboard: "Kotisivu",
    predictionModel: "WP4 uudelleenotto malli",
    update: "Päivitä",
    editPatientProfile: "Muokkaa potilasprofiilia",
    generalInformation: "Yleistiedot",
    fullName: "Koko nimi",
    countryOfOrigin: "Kotimaa",
    chf: "CHF",
    copd: "COPD",
    uti: "UTI",
    emailAddress: "Sähköpostiosoite",
    password: "Salasana",
    forgotPassword: "Unohtunut salasana",
    login: "Sisäänkirjautuminen",
    signInToYourAccount: "Kirjaudu sisään tilillesi",
    welcomeBack: "Tervetuloa takaisin",
    hospitalization_history: "SAIRAALAAN OTTAMISEN HISTORIA",
    usti: "UTI",
    deteriorated_more_services: "HUONONTUNUT ENEMMÄN PALVELUITA",
    stasis_ulcer: "STATIUSULCERAATIO",
    skin_tears_or_cut: "IHONREPIMISET TAI LEIKKAUKSET",
    lessons: "OPETUKSET",
    fallany: "KAATUMISET",
    other_fracture: "MUUT MURTUMAT",
    cancer: "SYÖPÄ",
    infuse: "INFUUSIO",
    dialysis: "DIALYYSI",
    er_visits_in_the_last_90_days_or_since_last_assessment: "Sairaalakäynnit viimeisten 90 päivän aikana tai viimeisimmän arvioinnin jälkeen",
    renal_failure: "MUNUAISVAURIO",
    gender: "SUKUPUOLI",
    irregular_pulse: "EPÄSÄÄNNÖLLINEN SYDÄMENLYÖNTI",
    unintended_weight_loss_of_5_percent_or_more_in_last_30_days: "Tarkoituksellinen painonpudotus 5 % tai enemmän viimeisten 30 päivän aikana",
    ulcer_wound_care_surgical_wound_care: "HAURAS HAAVAHOITO KIRURGINEN HAAVAHOITO",
    pressure_ulce: "PAINE ULCE",
    hip_fracture: "LONKKA MURTUMA",
    client_feels_having_a_poor_health: "ASIAKAS TUNTEE OLEVANSA HUONON TERVEYDEN",
    experiencing_flare_up_of_recurrent_condition_or_problem: "KOKEMUS TULEHDUKSESTA TOISTUVASTI TAI ONGELMASTA",
    adl_decline: "ADL LASKU",
    baseline_frailty_score: "PERUSLINJAN HELLIMINEN PISTE",
    baseline_frailty_subset1_cognition_collapsed: "PERUSLINJAN HELLIMINEN ALARYHMÄ1 KOGNITIO ROMAHTANUT",
    baseline_frailty_subset1_clinical_collapsed: "PERUSLINJAN HELLIMINEN ALARYHMÄ1 KLIININEN ROMAHTANUT",
    short_term_memory: "LYHYTAIKAINEN MUISTI",
    worsening_of_decision_making: "PÄÄTÖKSEN TEKEMISEN HUONONEMINEN",
    alzheimers: "ALZHEIMER",
    dementia_other_than_heart_disease: "MUU KUIN SYDÄNSAIRAUS",
    parkinsonism: "PARKINSONISM",
    eating: "SYÖMINEN",
    tranferring_oneself_from_surfaces: "SIIRTÄMINEN PINNOILTA",
    bladder_devices_indwelling_urinary_catheter: "RAKON LAITTEET SISÄLLÄ OLEVAT VIRTSAKATEET",
    pneumonia: "KEUHKONESTE",
    procedural_memory: "MUISTI",
    fever: "KUUME",
    diagnosis: "DIAGNOOSI",
    age: "IKÄ",
    unplanned_hospital_or_er_visit: "SUUNNITTELEMATON SAIRAALA TAI ENSIAPU KÄYNTI",
    number_of_comorbidities: "SAMANAIKAISTEN SAIRAUKSIEN LUKUMÄÄRÄ",
    unsteady_gait: "EPÄVARMA KÄYNTI",
    diuretics: "DIUREETTI",
    pain_scale: "KIPUASTEIKKO",
    client_feels_he_she_has_poor_health: "ASIAKAS KOKEE OLEVANSA HUONO TERVEYS",
    chess: "SHAKKI",
    adl_status_has_become_worse: "ADL TILA ON HUONONTUNUT",
    security_service: "TURVAPALVELU",
    visiting_nurses: "KÄYNTI HOITAJAT",
    client_went_out_of_the_house_1_day_a_week_or_no_days: "ASIAKAS MENI ULKONA 1 PÄIVÄ VIIKOSSA TAI EI PÄIVIÄ",
    impaired_vision: "HUONO NÄKÖ",
    caregivers_belief_client_capable: "OMAISHOIDON USKO ASIAKAS KYKENEE",
    drs: "LÄÄKÄRIT",
    psychotropic_medication: "PSYKOTROOPPISET LÄÄKKEET",
    bmi: "KMI",
    adl_long: "ADL PITKÄ",
    cancel: "Peruuta",
    saveAndClose: "TALLENNA JA SULJE",
    enterToSearch: "Paina Enter",
    viewModelList: "Näkymämallin luettelo",
    chancesHospitalizationInNext12Months: "Mahdollisuudet sairaalaan ottamiseen seuraavan 12 kuukauden aikana",
    inclusionRules: "Sisällytys säännöt",
    variableIndex: "Muuttujaindeksi",
    variableName: "Muuttujan nimi",
    variableValue: "Muuttujan arvo",
    valueSymetricOddScore: "Arvo (Symetric) Odd Score",
    oddSymetricScoreSet: "Outo Symetric-pistemäärä setti",
    riskGroups: "Riskiryhmät",
    threshold: "Kynnysarvo",
    dependent_value: "Vastuullinen arvo",
    performance: "Tehokkuus",
    sample_size: "Näytteen koko",
    previous_hospitalization_events_in_last_year: "EDELLISET SAIRAALAOLOT VIIME VUONNA",
    welcome: "Tervetuloa!",
    back: "Takaisin",
    frailtyPrediction: "Hajanaisuuden ennustaminen",
    viewModelProfile: "Näytä malliprofiili",
    users: "Käyttäjät",
    usersList: "Käyttäjälista",
    addNewUser: "Lisää uusi käyttäjä",
    name: "Nimi",
    email: "Sähköposti",
    isVerified: "Onko vahvistettu",
    yes: "Kyllä",
    no: "En",
    edit: "Muokkaa",
    resendActivationLink: "Lähetä aktivointilinkki uudelleen",
    add: "Lisää",
    user: "Käyttäjä",
    passwordConfirmation: "Salasanan vahvistus",
    newPatientProfile: "Uusi potilasprofiili",
    userForm: "Käyttäjäkaavake",
    modelProfile: "Malliprofiili",
    newModelProfile: "Uusi malliprofiili",
    locomodation_in_home: "Majoitus kotona",
    meal_preparation: "Ruuanvalmistus",
    stairs: "Portaat",
    short_term_memory_ok: "Lyhytaikainen muisti",
    alzheimers_disease: "Alzheimerin tauti",
    dementia_other_than_alzheimers_disease: "Muistisairaus muu kuin Alzheimerin tauti",
    paraplegia: "Paraplegia",
    mental_health_delusions: "Mielenterveys harhaluulot",
    mental_health_hallucinations: "Mielenterveys hallusinaatiot",
    bowel_continence: "Suolen toiminta",
    meal: "Ateria",
    housework: "Kotityöt",
    shopping: "Ostokset",
    iadl_alzheimers: "IADL Alzheimer",
    other_dementia: "Muut dementia",
    bath: "Kylpy",
    difficulty_meal_preparation: "Ruuanlaiton vaikeus",
    revised_iadl_hierarchy: "uudistettu IADL-hierarkia",
    short_memory_ok: "Lyhytaikainen muisti ok",
    iadl_decline: "IADL lasku",
    iadlModel: "WP4 IADL-malli",
    iadlProfileList: "IADL profiililista",
    iadlProfile: "IADL-profiili",
    viewIadlModelList: "Näytä IADL-mallilista",
    viewIadlModel: "Näytä IADL-malli",
    individualTreatment: "Yksilöllinen hoito",
    seeAll: "Näytä kaikki",
    iadlDecline: "HC: IADL-heikentyminen",
    hospitalization: "HC: Sairaalahoito",
    qualityOfLife: "NH: Elämänlaatu",
    frailty: "NH: Hauraus",
    viewQualityOfLifeList: "Näytä elämänlaadun lista",
    viewFrailtyList: "Näytä haurauslista",
    viewFrailty: "Näytä hauraus",
    qualityOfLifeN: "Elämänlaatu",
    frailtyN: "Hauraus",
    baseOnModel: "Mallin perusteella",
    item: "Tuote",
    addSuccessfully: "Lisätty onnistuneesti",
    updateSuccessfully: "Päivitetty onnistuneesti",
    errorMessage: "Ops, jotain meni pieleen, yritä uudelleen",
    questionSubmittedSuccess: "Kysymys lähetetty onnistuneesti",
    deletedSuccess: "Poistettu onnistuneesti",
    resetPasswordSuccessMsg: "Salasanan nollauslinkki on lähetetty sähköpostiisi onnistuneesti.",
    successLogin: "Olet kirjautunut sisään onnistuneesti",
    otpSuccessMsg: "OTP lähetetty sähköpostiisi onnistuneesti",
    alreadyRegistered: "Jo rekisteröity!",
    otpVerifiedMsg: "OTP vahvistettu onnistuneesti!",
    otpSentSuccessMsg: "OTP lähetetty onnistuneesti!",
    registerSuccessMsg: "Rekisteröity onnistuneesti!",
    tokenInvalidMsg: "Token on virheellinen tai vanhentunut, yritä uudelleen",
    passwordResetSuccessMsg: "Salasana nollattu onnistuneesti!",
    yourRequestSubmitSuccessMsg: "Pyyntösi on lähetetty onnistuneesti",
    patientImportSuccessMsg: "Potilaan tuonti onnistui",
    deletedSuccessMsg: "Poistettu onnistuneesti",
    accountAlreadyExistsMsg: "Tilisi on rekisteröity onnistuneesti, kirjaudu nyt sisään!",
    fileUploadSuccessMsg: "Tiedosto ladattu onnistuneesti",
    fileDeleteMsg: "Tiedosto poistettu onnistuneesti",
    click: "Klikkaa",
    here: "Täällä",
    postQuestionnaireSentence: "tässä linkissä ja täytä lomake jatkotoimenpiteitä varten",
    postQuestionnaire: "jälkikysely",
    hospitalizationTooltip2: "Sairaalahoitoon joutumisen todennäköisyys seuraavien 12 kuukauden aikana.",
    adlTooltip2: "Todennäköisyys merkittävälle heikentymiselle IADL:ssä seuraavien 12 kuukauden aikana.",
    patient: 'Potilas',
    predictionTopic: "Ennusteet 12 kuukauden kuluttua",
    riskOfDeclineOfEvent: "Tapahtuman vähenemisen riski",
    whatIfYouDecideTo: "Mitä jos päätät:",
    stopAntipsychotics: "Lopettaa antipsykoottiset lääkkeet",
    startAnticholinergics: "Aloittaa antikolinergit",
    startPhysiotherapy: "Aloittaa tai jatkaa fysioterapian",
    predictedChange: "Ennustettu muutos",
    adl: "ADL",
    iadl: "IADL",
    cps: "CPS",
    falls: "KAATUMISET",
    configuration: "Konfiguraatio",
    code: "Koodi",
    label: "Tunniste",
    index: "Indeksi",
    wp4: "WP4",
    configurations: "Konfiguraatiot",
    configurationWp4Form: "WP4-konfiguraatiolomake",
    configurationWp4SectionForm: "WP4-sektion konfiguraatiolomake",
    wp4Section: "WP4-sektio",
    title: "Otsikko",
    section: "Osa",
    isEmailVerified: "Onko sähköposti vahvistettu?",
    areYourSureWantToLogout: "Haluatko varmasti kirjautua ulos?",
    postQuestion: "päätöskyselyä varten.",
    toProvideFeedback: "antaa palautetta.",
    logout: "Kirjaudu ulos",
    clone: "Klooni",
    cloneSuccessMsg: "Potilastiedot kloonattiin onnistuneesti",
    codeSystem: "Koodijärjestelmä",
    importExternalPatient: "Tuo ulkoinen potilas",
    addPatient: "Lisää potilas",
    patientList: "Potilasluettelo",
    patientForm: "Potilaslomake",
    save: "Tallenna",
    link1: "Linkki1",
    link2: "Linkki2",
    patientFeedbackLink: "Potilaspalautelinkki",
    forDecisionQuestionnaire: "Linkki seuraavaan kyselyyn",
    link: "Linkki",
    showRaiData: "Näytä RAI-tiedot",
    startPhysiotherapyContent: "aikakehys: enintään 6 kuukautta; Sairaalahoitoon ja kaatumisiin käytettiin 90 päivän takaisinseuranta-aikaa seurannassa. Fysioterapian määritelmä: vähintään 4 istuntoa viikossa, jokainen istunto kestää vähintään 15 minuuttia, lukuun ottamatta kognitiivisen suorituskyvyn asteikkoa (CPS), jossa se on 5 päivää",
    startPhysiotherapyContent1: "Yksilöllinen hoitovaikutus (ITE) on ero ennustetun hoitovaikutuksen ja ennustetun vaikutuksen välillä, jos potilas ei saa hoitoa. Ennustettu vaikutus alle 0 osoittaa vähemmän heikkenemistä, ei heikkenemistä tai suurempaa parannusta terapialla verrattuna hoitamatta jättämiseen. Ennustettu vaikutus yli 0 osoittaa suurempaa heikkenemistä hoidolla verrattuna hoitamatta jättämiseen.",
    startPhysiotherapyContent2: "Yksilöllinen hoitovaikutus (ITE) on ero ennustetun hoitovaikutuksen ja ennustetun vaikutuksen välillä, jos potilas ei saa hoitoa. Ennustettu vaikutus yli 0 osoittaa vähemmän heikkenemistä, ei heikkenemistä tai suurempaa parannusta terapialla verrattuna hoitamatta jättämiseen. Ennustettu vaikutus alle 0 osoittaa suurempaa heikkenemistä hoidolla verrattuna hoitamatta jättämiseen.",
    stopAntipsychoticsContent1: "Yksilöllinen hoitovaikutus (ITE) on ero ennustetun vaikutuksen välillä antipsykoottisten lääkkeiden lopettamisen jälkeen ja ennustetun vaikutuksen välillä lääkityksen jatkamisessa. Ennustettu vaikutus alle 0 osoittaa CHESS:in vähempää heikkenemistä, ei heikkenemistä tai suurempaa parantumista antipsykoottisten lääkkeiden lopettamisen yhteydessä verrattuna lääkityksen jatkamiseen. Ennustettu vaikutus yli 0 osoittaa CHESS:in suurempaa heikkenemistä antipsykoottisten lääkkeiden lopettamisen yhteydessä verrattuna lääkityksen jatkamiseen. Aikakehys: enintään 12 kuukautta",
    stopAntipsychoticsContentAdl: "Yksilöllinen hoitovaikutus (ITE) on ero ennustetun vaikutuksen välillä antipsykoottisten lääkkeiden lopettamisen jälkeen ja ennustetun vaikutuksen välillä lääkityksen jatkamisessa. Ennustettu vaikutus alle 0 osoittaa ADL:n vähempää heikkenemistä, ei heikkenemistä tai suurempaa parantumista antipsykoottisten lääkkeiden lopettamisen yhteydessä verrattuna lääkityksen jatkamiseen. Ennustettu vaikutus yli 0 osoittaa ADL:n suurempaa heikkenemistä antipsykoottisten lääkkeiden lopettamisen yhteydessä verrattuna lääkityksen jatkamiseen. Aikakehys: enintään 12 kuukautta",
    stopAntipsychoticsContentCps: "Yksilöllinen hoitovaikutus (ITE) on ero ennustetun vaikutuksen välillä antipsykoottisten lääkkeiden lopettamisen jälkeen ja ennustetun vaikutuksen välillä lääkityksen jatkamisessa. Ennustettu vaikutus alle 0 osoittaa CPS:n vähempää heikkenemistä, ei heikkenemistä tai suurempaa parantumista antipsykoottisten lääkkeiden lopettamisen yhteydessä verrattuna lääkityksen jatkamiseen. Ennustettu vaikutus yli 0 osoittaa CPS:n suurempaa heikkenemistä antipsykoottisten lääkkeiden lopettamisen yhteydessä verrattuna lääkityksen jatkamiseen. Aikakehys: enintään 12 kuukautta",
    stopAntipsychoticsContentHospitalization: "Yksilöllinen hoitovaikutus (ITE) on ero ennustetun vaikutuksen välillä antipsykoottisten lääkkeiden lopettamisen jälkeen ja ennustetun vaikutuksen välillä lääkityksen jatkamisessa. Ennustettu vaikutus alle 0 osoittaa pienemmän todennäköisyyden sairaalahoitoon antipsykoottisten lääkkeiden lopettamisen yhteydessä verrattuna lääkityksen jatkamiseen. Ennustettu vaikutus yli 0 osoittaa suuremman todennäköisyyden sairaalahoitoon antipsykoottisten lääkkeiden lopettamisen yhteydessä verrattuna lääkityksen jatkamiseen. Aikakehys: enintään 12 kuukautta",
    startAnticholinergicsAdl: "Yksilöllinen hoitovaikutus (ITE) on ero ennustetun vaikutuksen välillä antikolinergisten lääkkeiden aloittamisen jälkeen ja ennustetun vaikutuksen välillä, jos potilas ei saa lääkitystä. Ennustettu vaikutus alle 0 osoittaa vähemmän heikkenemistä, ei heikkenemistä tai suurempaa parannusta ADL:ssa lääkityksen aloittamisen jälkeen verrattuna lääkityksen aloittamatta jättämiseen. Ennustettu vaikutus yli 0 osoittaa suurempaa heikkenemistä ADL:ssa lääkityksen aloittamisen jälkeen verrattuna lääkityksen aloittamatta jättämiseen. aikakehys: enintään 12 kuukautta",
    startAnticholinergicsCps: "Yksilöllinen hoitovaikutus (ITE) on ero ennustetun vaikutuksen välillä antikolinergisten lääkkeiden aloittamisen jälkeen ja ennustetun vaikutuksen välillä, jos potilas ei saa lääkitystä. Ennustettu vaikutus alle 0 osoittaa vähemmän heikkenemistä, ei heikkenemistä tai suurempaa parannusta CPS:ssä lääkityksen aloittamisen jälkeen verrattuna lääkityksen aloittamatta jättämiseen. Ennustettu vaikutus yli 0 osoittaa suurempaa heikkenemistä CPS:ssä lääkityksen aloittamisen jälkeen verrattuna lääkityksen aloittamatta jättämiseen. aikakehys: enintään 12 kuukautta",
    startAnticholinergicsHospitalization: "Yksilöllinen hoitovaikutus (ITE) on ero ennustetun vaikutuksen välillä antikolinergisten lääkkeiden aloittamisen jälkeen ja ennustetun vaikutuksen välillä, jos potilas ei saa lääkitystä. Ennustettu vaikutus alle 0 osoittaa pienemmän todennäköisyyden sairaalahoitoon lääkityksen aloittamisen jälkeen verrattuna lääkityksen aloittamatta jättämiseen. Ennustettu vaikutus yli 0 osoittaa suuremman todennäköisyyden sairaalahoitoon lääkityksen aloittamisen jälkeen verrattuna lääkityksen aloittamatta jättämiseen. aikakehys: enintään 12 kuukautta",
    startAnticholinergicsFalls: "Yksilöllinen hoitovaikutus (ITE) on ero ennustetun vaikutuksen välillä antikolinergisten lääkkeiden aloittamisen jälkeen ja ennustetun vaikutuksen välillä, jos potilas ei saa lääkitystä. Ennustettu vaikutus alle 0 osoittaa pienemmän todennäköisyyden kaatumisiin lääkityksen aloittamisen jälkeen verrattuna lääkityksen aloittamatta jättämiseen. Ennustettu vaikutus yli 0 osoittaa suuremman todennäköisyyden kaatumisiin lääkityksen aloittamisen jälkeen verrattuna lääkityksen aloittamatta jättämiseen. aikakehys: 90 päivän takaisinkatsaus 12 kuukauden jälkeen",
    questionnairePopupHeading: "Yleinen vastuuvapauslauseke kaikille ennusteille",
    questionnairePopupHeadingList1: "Käytä omaa kliinistä harkintaasi ja peilaa omaa asiantuntemustasi lopulliseen päätöksentekoon, sillä mallien tarkkuus vaihtelee yksilöiden välillä.",
    questionnairePopupHeadingList2: "Nämä ennusteet on kehitetty ja validoitu I-CARE4OLD-hankkeessa.",
    questionnairePopupHeadingList3: "Ennusteet perustuvat kehittyneisiin analyyseihin, jotka sisältävät koneoppimista. Analyyseissä on hyödynnetty luotettavaa tieteellistä näyttöä ja korkealaatuista hoitotietoaineistoa useista maista.",
    questionnairePopupHeadingList4: "Validoinnit tehtiin isolla ja korkealaatuisella pitkittäisellä kotihoidon ja asumispalveluiden asiakasaineistolla Belgiassa, Kanadassa, Suomessa, Hongkongissa, Italiassa, Uudessa-Seelannissa, Alankomaissa ja Yhdysvalloissa.",
    questionnairePopupHeadingList5: "Lisätietoja: www.icare4old.eu",
    close: "Sulje",
    description: "Kuvaus",
    wp5: "Wp5",
    wp6: "Wp6",
    wp6Prediction: "Wp6 Ennuste",
    configurationWp5Form: "Wp5-lomakkeen määritys",
    configurationWp6Form: "Wp6-lomakkeen määritys",
    boxType: "Laatikkotyyppi",
    addNewSection: "Lisää uusi osio",
    type: "Luokittelu",
    translation: "Käännös",
    upload: "Lataa",
    noRecordFound: "Ei tuloksia",
    attachments: "Liitteet",
    questionnairePopupHeadingList6: "tällä linkillä ja täytä lomake jatkokäsittelyä varten",
    preQuestionnaire: "esikysely",
    homeCare: "Kotipalvelu",
    nursingHome: "Hoitokoti",
    afterGeneratePredictionPopup: "Tutustuitko potilasprofiiliin?",
    thankYou: "Kiitos!",
    predictionIn12Months: "Ennusteet 12 kuukauden aikana",
    functionWorsening: "Fyysisen toimintakyvyn heikkeneminen (ADL+IADL)",
    cognitionWorsening: "Kognition heikkeneminen (CPS)",
    healthInstability: "Terveydentilan vakaus (CHESS)",
    otherFalls: "Muu (Kaatumiset)",
    qualityOfLifeWorsening: "Elämänlaadun heikkeneminen (HUI3)",
    riskOfDecline: "Tapahtuman riski",
    whatIfYouDecide: "Mitä jos päätät:",
    probabilityOfHospitalization: "Todennäköisyys joutua sairaalaan seuraavien 12 kuukauden aikana.",
    probabilityOfWorseningIADL: "Todennäköisyys merkittävään heikkenemiseen IADL:ssa seuraavien 12 kuukauden aikana.",
    therFallsInfoBox: "Yksilöllinen hoitovaikutus (ITE) on ero ennustetun vaikutuksen välillä, kun antikolinerginen lääkitys aloitetaan, ja ennustetun vaikutuksen välillä, jos potilas ei saa lääkitystä. Ennustettu vaikutus, joka on alle 0, osoittaa pienemmän kaatumisen todennäköisyyden lääkityksen alkamisen jälkeen verrattuna ei-lääkitykseen. Korkeampi vaikutus osoittaa suuremman kaatumisen todennäköisyyden lääkityksen kanssa verrattuna ilman lääkitystä. Aikataulu: takautuva tarkastelujakso 90 päivää 12 kuukauden jälkeen.",
    lastAllInfoBox: "Hoitovaikutus on ero ennustetun hoitovaikutuksen ja ennustetun vaikutuksen välillä, jos potilas ei saa hoitoa. Ennustettu vaikutus alle 0 osoittaa vähemmän heikkenemistä, ei heikkenemistä tai suurempaa parannusta terapialla verrattuna hoitamatta jättämiseen. Ennustettu vaikutus yli 0 osoittaa suurempaa heikkenemistä hoidolla verrattuna hoitamatta jättämiseen.",
    hospitalizations: "Sairaalan joutumisen riski"
};


export default {
    locale: "fi-FI",
    messages: {
        title: titles
    },
};
