const titles = {
    patientProfileList: "Patiëntprofiellijst",
    hello: "Hallo",
    patientName: "Patiëntnaam",
    patientId: "Patiënt-ID",
    creationDate: "Aanmaakdatum",
    lastUpdate: "Laatste update",
    status: "Status",
    action: "Actie",
    profileId: "Profiel-ID",
    sizeOfProfilePopulation: "Grootte van profielpopulatie",
    numberOfInclusionRules: "Aantal inclusieregels",
    numberOfRiskGroups: "Aantal risicogroepen",
    modelProfileList: "Model profiellijst",
    view: "Bekijken",
    export: "Exporteren",
    import: "Importeren",
    completed: "Voltooid",
    delete: "Verwijderen",
    generatePrediction: "Voorspelling genereren",
    draft: "Concept",
    addNewPatient: "Nieuwe patiënt toevoegen",
    patientProfile: "Patiëntenprofiel",
    predictionGenerators: "Voorspellingsgeneratoren",
    readMission: "Heropname",
    basedOnModels: "Gebaseerd op modellen",
    frailtyPredictions: "Voorspellingen van kwetsbaarheid",
    predictedIndividualTreatmentEffect: "Voorspelde individuele behandelingseffect",
    deleteProfile: "Profiel verwijderen",
    editProfile: "Profiel bewerken",
    dashboard: "Dashboard",
    predictionModel: "WP4 heropname model",
    update: "Bijwerken",
    editPatientProfile: "Patiëntenprofiel bewerken",
    generalInformation: "Algemene informatie",
    fullName: "Volledige naam",
    countryOfOrigin: "Land van herkomst",
    chf: "Hartfalen",
    copd: "COPD",
    uti: "Urinary Tract Infection (UTI)",
    emailAddress: "E-mailadres",
    password: "Wachtwoord",
    forgotPassword: "Wachtwoord vergeten",
    login: "Inloggen",
    signInToYourAccount: "Meld u aan bij uw account",
    welcomeBack: "Welkom terug",
    hospitalization_history: "Ziekenhuisopname geschiedenis",
    usti: "UTI",
    deteriorated_more_services: "Meer verslechterde diensten",
    stasis_ulcer: "Stase-ulcus",
    skin_tears_or_cut: "Huidscheuren of snijwonden",
    lessons: "Lessen",
    fallany: "Valpartij",
    other_fracture: "Andere breuk",
    cancer: "Kanker",
    infuse: "Infuseren",
    dialysis: "Dialyse",
    er_visits_in_the_last_90_days_or_since_last_assessment: "Spoedeisende hulpbezoeken in de laatste 90 dagen of sinds de laatste beoordeling",
    renal_failure: "Nierfalen",
    gender: "Geslacht",
    irregular_pulse: "Onregelmatige pols",
    unintended_weight_loss_of_5_percent_or_more_in_last_30_days: "Onbedoeld gewichtsverlies van 5% of meer in de laatste 30 dagen",
    ulcer_wound_care_surgical_wound_care: "Zweerwondverzorging of chirurgische wondverzorging",
    pressure_ulce: "Decubitus ulcus",
    hip_fracture: "Heupfractuur",
    client_feels_having_a_poor_health: "Cliënt voelt zich slecht gezond",
    experiencing_flare_up_of_recurrent_condition_or_problem: "Ervaart opvlamming van terugkerende aandoening of probleem",
    adl_decline: "ADL-afname",
    baseline_frailty_score: "Baselinewaarde van kwetsbaarheidsscore",
    baseline_frailty_subset1_cognition_collapsed: "Baselinewaarde van kwetsbaarheidssubset1 cognitie samengevoegd",
    baseline_frailty_subset1_clinical_collapsed: "Baselinewaarde van kwetsbaarheidssubset1 kliniek samengevoegd",
    short_term_memory: "Kortetermijngeheugen",
    worsening_of_decision_making: "Verslechtering van besluitvorming",
    alzheimers: "Alzheimer",
    dementia_other_than_heart_disease: "Dementie anders dan hartziekte",
    parkinsonism: "Parkinsonisme",
    eating: "Eten",
    tranferring_oneself_from_surfaces: "Overdragen van zichzelf van oppervlakken",
    bladder_devices_indwelling_urinary_catheter: "Blaastraining met inwendige urinekatheter",
    pneumonia: "Longontsteking",
    procedural_memory: "Procedureel geheugen",
    fever: "Koorts",
    diagnosis: "Diagnose",
    age: "Leeftijd",
    unplanned_hospital_or_er_visit: "Ongepland ziekenhuis- of SEH-bezoek",
    number_of_comorbidities: "Aantal comorbiditeiten",
    unsteady_gait: "Onstabiel looppatroon",
    diuretics: "Diuretica",
    pain_scale: "Pijnschaal",
    client_feels_he_she_has_poor_health: "Cliënt voelt zich slecht gezond",
    chess: "Schaakspel",
    adl_status_has_become_worse: "ADL-status is verslechterd",
    security_service: "Beveiligingsservice",
    visiting_nurses: "Thuisverpleegkundigen",
    client_went_out_of_the_house_1_day_a_week_or_no_days: "Cliënt ging het huis uit (1 dag per week of geen dagen)",
    impaired_vision: "Verminderd zicht",
    caregivers_belief_client_capable: "Overtuiging van de verzorgers dat de cliënt in staat is",
    drs: "Dokters",
    psychotropic_medication: "Psychotrope medicatie",
    bmi: "BMI",
    adl_long: "ADL Lang",
    cancel: "Annuleren",
    saveAndClose: "Opslaan en sluiten",
    enterToSearch: "Enter om te zoeken",
    viewModelList: "Lijst met modelweergaven",
    chancesHospitalizationInNext12Months: "Kans op ziekenhuisopname in de komende 12 maanden",
    inclusionRules: "Insluitingsregels",
    variableIndex: "Variabele index",
    variableName: "Variabelenaam",
    variableValue: "Variabele waarde",
    valueSymetricOddScore: "Waarde (symmetrische) oneven score",
    oddSymetricScoreSet: "Oneven symmetrische score ingesteld",
    riskGroups: "Risicogroepen",
    threshold: "Drempel",
    dependent_value: "Afhankelijke waarde",
    performance: "Prestatie",
    sample_size: "Steekproefgrootte",
    previous_hospitalization_events_in_last_year: "Eerdere ziekenhuisopnames in het afgelopen jaar",
    welcome: "Welkom!",
    back: "Terug",
    frailtyPrediction: "Kwetsbaarheidsvoorspelling",
    viewModelProfile: "Modelweergaveprofiel",
    users: "Gebruikers",
    usersList: "Gebruikerslijst",
    addNewUser: "Nieuwe gebruiker toevoegen",
    name: "Naam",
    email: "E-mail",
    isVerified: "Is geverifieerd",
    yes: "Ja",
    no: "Nee",
    edit: "Bewerken",
    resendActivationLink: "Activeringslink opnieuw verzenden",
    add: "Toevoegen",
    user: "Gebruiker",
    passwordConfirmation: "Wachtwoordbevestiging",
    newPatientProfile: "Nieuw patiëntenprofiel",
    userForm: "Gebruikersformulier",
    modelProfile: "Modelprofiel",
    newModelProfile: "Nieuw modelprofiel",
    locomodation_in_home: "Accommodatie thuis",
    meal_preparation: "Maaltijdvoorbereiding",
    stairs: "Trappen",
    short_term_memory_ok: "Korte termijn geheugen",
    alzheimers_disease: "Alzheimer's ziekte",
    dementia_other_than_alzheimers_disease: "Dementie anders dan Alzheimer's ziekte",
    paraplegia: "Paraplegie",
    mental_health_delusions: "Mentale gezondheid waanvoorstellingen",
    mental_health_hallucinations: "Mentale gezondheid hallucinaties",
    bowel_continence: "Darmcontinuïteit",
    meal: "Maaltijd",
    housework: "Huishoudelijk werk",
    shopping: "Winkelen",
    iadl_alzheimers: "IADL Alzheimer",
    other_dementia: "Andere dementie",
    bath: "Bad",
    difficulty_meal_preparation: "Moeilijkheid maaltijdvoorbereiding",
    revised_iadl_hierarchy: "herziene IADL-hiërarchie",
    short_memory_ok: "Korte termijn geheugen ok",
    iadl_decline: "IADL achteruitgang",
    iadlModel: "WP4 IADL-model",
    iadlProfileList: "IADL Profiel Lijst",
    iadlProfile: "IADL profiel",
    viewIadlModelList: "Bekijk IADL Model Lijst",
    viewIadlModel: "Bekijk IADL Model",
    individualTreatment: "Individuele behandeling",
    seeAll: "Alles zien",
    iadlDecline: "HC: IADL-afname",
    hospitalization: "HC: Hospitalisatie",
    qualityOfLife: "NH: Kwaliteit van leven",
    frailty: "NH: Kwetsbaarheid",
    viewQualityOfLifeList: "Bekijk kwaliteitslijst van leven",
    viewFrailtyList: "Bekijk kwetsbaarheid lijst",
    viewFrailty: "Bekijk kwetsbaarheid",
    qualityOfLifeN: "Kwaliteit van leven",
    frailtyN: "Kwetsbaarheid",
    baseOnModel: "Gebaseerd op model",
    item: "Artikel",
    addSuccessfully: "Succesvol toegevoegd",
    updateSuccessfully: "Succesvol bijgewerkt",
    errorMessage: "Oeps, er is iets misgegaan, probeer het opnieuw",
    questionSubmittedSuccess: "Vraag succesvol ingediend",
    deletedSuccess: "Succesvol verwijderd",
    resetPasswordSuccessMsg: "De link om het wachtwoord opnieuw in te stellen is succesvol naar uw e-mail verzonden.",
    successLogin: "Je bent succesvol ingelogd",
    otpSuccessMsg: "OTP succesvol naar je mail verzonden",
    alreadyRegistered: "Al geregistreerd!",
    otpVerifiedMsg: "OTP succesvol geverifieerd!",
    otpSentSuccessMsg: "OTP succesvol verzonden!",
    registerSuccessMsg: "Succesvol geregistreerd!",
    tokenInvalidMsg: "Token ongeldig of verlopen, probeer het opnieuw",
    passwordResetSuccessMsg: "Wachtwoord succesvol opnieuw ingesteld!",
    yourRequestSubmitSuccessMsg: "Uw verzoek is succesvol ingediend",
    patientImportSuccessMsg: "Patiëntimport succesvol",
    deletedSuccessMsg: "Succesvol verwijderd",
    accountAlreadyExistsMsg: "Uw account is succesvol geregistreerd bij ons, log nu in!",
    fileUploadSuccessMsg: "Bestand succesvol geüpload",
    fileDeleteMsg: "Bestand succesvol verwijderd",
    click: "Klik",
    here: "Hier",
    postQuestionnaireSentence: "op deze link en vul het formulier in voor verdere verwerking",
    postQuestionnaire: "post-enquête",
    hospitalizationTooltip2: "Waarschijnlijkheid van opname in een medische instelling in de komende 12 maanden.",
    adlTooltip2: "Waarschijnlijkheid van een aanzienlijke verslechtering in IADL in de komende 12 maanden.",
    patient: 'Patiënt',
    predictionTopic: "Voorspellingen over 12 maanden",
    riskOfDeclineOfEvent: "Risico van achteruitgang / gebeurtenis",
    whatIfYouDecideTo: "Wat als je besluit om:",
    stopAntipsychotics: "Stoppen met antipsychotica",
    startAnticholinergics: "Beginnen met anticholinergica",
    "startPhysiotherapy": "Start of voortzetting fysiotherapie",
    predictedChange: "Voorspelde verandering",
    adl: "ADL",
    iadl: "IADL",
    cps: "CPS",
    falls: "VALPARTIJEN",
    configuration: "Configuratie",
    code: "Code",
    label: "Label",
    index: "Index",
    wp4: "WP4",
    configurations: "Configuraties",
    configurationWp4Form: "WP4-configuratieformulier",
    configurationWp4SectionForm: "WP4-sectieconfiguratieformulier",
    wp4Section: "WP4-sectie",
    title: "Titel",
    section: "Sectie",
    isEmailVerified: "Is e-mail geverifieerd?",
    areYourSureWantToLogout: "Weet u zeker dat u wilt uitloggen?",
    postQuestion: "voor beslissingsvragenlijst.",
    toProvideFeedback: "om feedback te geven.",
    logout: "Afmelden",
    clone: "Klonen",
    cloneSuccessMsg: "Patiëntgegevens succesvol gekloond",
    codeSystem: "Codesysteem",
    importExternalPatient: "Externe patiënt importeren",
    addPatient: "Patiënt toevoegen",
    patientList: "Patiëntenlijst",
    patientForm: "Patiëntenformulier",
    save: "Opslaan",
    link1: "Link1",
    link2: "Link2",
    patientFeedbackLink: "Patiënt feedback link",
    forDecisionQuestionnaire: "voor beslissingsvragenlijst",
    link: "Link",
    showRaiData: "Toon RAI-gegevens",
    startPhysiotherapyContent: "tijdspanne: tot 6 maanden; Voor ziekenhuisopnames en vallen werd een terugblikperiode van 90 dagen gebruikt bij follow-up. Definitie van PT: minstens 4 sessies per week, elke sessie van minimaal 15 minuten, behalve voor de Cognitive Performance Scale (CPS), waar het 5 dagen is",
    startPhysiotherapyContent1: "Het individuele behandelingseffect (ITE) is het verschil tussen het voorspelde effect onder behandeling en het voorspelde effect als de patiënt geen behandeling ontvangt. Een voorspeld effect lager dan 0 duidt op minder achteruitgang, geen achteruitgang, of een grotere verbetering met therapie in vergelijking met geen therapie. Een voorspeld effect hoger dan 0 duidt op een grotere achteruitgang met therapie in vergelijking met geen therapie",
    startPhysiotherapyContent2: "Het individuele behandelingseffect (ITE) is het verschil tussen het voorspelde effect onder behandeling en het voorspelde effect als de patiënt geen behandeling ontvangt. Een voorspeld effect hoger dan 0 duidt op minder achteruitgang, geen achteruitgang, of een grotere verbetering met therapie in vergelijking met geen therapie. Een voorspeld effect lager dan 0 duidt op een grotere achteruitgang met therapie in vergelijking met geen therapie",
    stopAntipsychoticsContent1: "Het individuele behandelingseffect (ITE) is het verschil tussen het voorspelde effect na het stoppen met antipsychotische medicatie en het voorspelde effect bij voortzetting van de medicatie. Een voorspeld effect van minder dan 0 duidt op minder achteruitgang, geen achteruitgang, of een grotere verbetering van CHESS bij stopzetten van antipsychotische medicatie in vergelijking met voortzetting van medicatie. Een voorspeld effect groter dan 0 duidt op grotere achteruitgang van CHESS bij stopzetten van antipsychotische medicatie in vergelijking met voortzetting van medicatie. Tijdspanne: tot 12 maanden",
    stopAntipsychoticsContentAdl: "Het individuele behandelingseffect (ITE) is het verschil tussen het voorspelde effect na het stoppen met antipsychotische medicatie en het voorspelde effect bij voortzetting van de medicatie. Een voorspeld effect van minder dan 0 duidt op minder achteruitgang, geen achteruitgang, of een grotere verbetering van ADL bij stopzetten van antipsychotische medicatie in vergelijking met voortzetting van medicatie. Een voorspeld effect groter dan 0 duidt op grotere achteruitgang van ADL bij stopzetten van antipsychotische medicatie in vergelijking met voortzetting van medicatie. Tijdspanne: tot 12 maanden",
    stopAntipsychoticsContentCps: "Het individuele behandelingseffect (ITE) is het verschil tussen het voorspelde effect na het stoppen met antipsychotische medicatie en het voorspelde effect bij voortzetting van de medicatie. Een voorspeld effect van minder dan 0 duidt op minder achteruitgang, geen achteruitgang, of een grotere verbetering van CPS bij stopzetten van antipsychotische medicatie in vergelijking met voortzetting van medicatie. Een voorspeld effect groter dan 0 duidt op grotere achteruitgang van CPS bij stopzetten van antipsychotische medicatie in vergelijking met voortzetting van medicatie. Tijdspanne: tot 12 maanden",
    stopAntipsychoticsContentHospitalization: "Het individuele behandelingseffect (ITE) is het verschil tussen het voorspelde effect na het stoppen met antipsychotische medicatie en het voorspelde effect bij voortzetting van de medicatie. Een voorspeld effect van minder dan 0 duidt op een lagere kans op ziekenhuisopname bij stopzetten van antipsychotische medicatie in vergelijking met voortzetting van medicatie. Een voorspeld effect groter dan 0 duidt op een hogere kans op ziekenhuisopname bij stopzetten van antipsychotische medicatie in vergelijking met voortzetting van medicatie. Tijdspanne: tot 12 maanden",
    startAnticholinergicsAdl: "Het individuele behandelingseffect (ITE) is het verschil tussen het voorspelde effect na het starten van anticholinergische medicatie en het voorspelde effect als de patiënt geen medicatie ontvangt. Een voorspeld effect lager dan 0 duidt op minder achteruitgang, geen achteruitgang, of een grotere verbetering van ADL na het starten van medicatie in vergelijking met geen medicatie. Een voorspeld effect hoger dan 0 duidt op een grotere achteruitgang van ADL met medicatie in vergelijking met geen medicatie. Tijdspanne: tot 12 maanden",
    startAnticholinergicsCps: "Het individuele behandelingseffect (ITE) is het verschil tussen het voorspelde effect na het starten van anticholinergische medicatie en het voorspelde effect als de patiënt geen medicatie ontvangt. Een voorspeld effect lager dan 0 duidt op minder achteruitgang, geen achteruitgang, of een grotere verbetering van CPS na het starten van medicatie in vergelijking met geen medicatie. Een voorspeld effect hoger dan 0 duidt op een grotere achteruitgang van CPS met medicatie in vergelijking met geen medicatie. Tijdspanne: tot 12 maanden",
    startAnticholinergicsHospitalization: "Het individuele behandelingseffect (ITE) is het verschil tussen het voorspelde effect na het starten van anticholinergische medicatie en het voorspelde effect als de patiënt geen medicatie ontvangt. Een voorspeld effect lager dan 0 duidt op een lagere kans op ziekenhuisopname na het starten van medicatie in vergelijking met geen medicatie. Een voorspeld effect hoger dan 0 duidt op een hogere kans op ziekenhuisopname met medicatie in vergelijking met geen medicatie. Tijdspanne: tot 12 maanden",
    startAnticholinergicsFalls: "Het individuele behandelingseffect (ITE) is het verschil tussen het voorspelde effect na het starten van anticholinergische medicatie en het voorspelde effect als de patiënt geen medicatie ontvangt. Een voorspeld effect lager dan 0 duidt op een lagere kans op vallen na het starten van medicatie in vergelijking met geen medicatie. Een voorspeld effect hoger dan 0 duidt op een hogere kans op vallen met medicatie in vergelijking met geen medicatie. Tijdspanne: een terugblikperiode van 90 dagen na 12 maanden",
    questionnairePopupHeading: "Dichiarazione generale per tutte le previsioni",
    questionnairePopupHeadingList1: "Si prega di prestare attenzione nelle decisioni e di ponderare la propria esperienza clinica nella decisione finale, poiché l'accuratezza dei modelli varia tra i destinatari delle cure.",
    questionnairePopupHeadingList2: "Queste previsioni sono state sviluppate e validate dal consorzio I-CARE4OLD",
    questionnairePopupHeadingList3: "Le previsioni sono state informate da analisi avanzate, incluso il machine learning. Abbiamo applicato la migliore scienza su dati di routine di alta qualità provenienti da più paesi.",
    questionnairePopupHeadingList4: "Le validazioni sono state effettuate su campioni molto ampi di dati longitudinali di alta qualità su destinatari di cure reali in assistenza domiciliare o case di riposo in Belgio, Canada, Finlandia, Hong Kong, Italia, Nuova Zelanda, Paesi Bassi e USA.",
    questionnairePopupHeadingList5: "Meer info: www.icare4old.eu / Richtlijnen",
    close: "Chiudi",
    description: "Beschrijving",
    wp5: "Wp5",
    wp6: "Wp6",
    wp6Prediction: "Wp6 Voorspelling",
    configurationWp5Form: "Configuratie Wp5 Formulier",
    configurationWp6Form: "Configuratie Wp6 Formulier",
    boxType: "Doostype",
    addNewSection: "Nieuwe sectie toevoegen",
    translation: "Vertaling",
    upload: "Uploaden",
    noRecordFound: "Geen record gevonden",
    attachments: "Bijlagen",
    questionnairePopupHeadingList6: "via deze link en vul het formulier in voor verdere verwerking",
    preQuestionnaire: "voorvragenlijst",
    homeCare: "Thuiszorg",
    nursingHome: "Verpleeghuis",
    afterGeneratePredictionPopup: "Ben je vertrouwd geraakt met het patiëntenprofiel?",
    thankYou: "Dank je!",
    predictionIn12Months: "Voorspellingen in 12 maanden",
    functionWorsening: "Functie verslechtering (ADL + IADL)",
    cognitionWorsening: "Cognitieve verslechtering (CPS)",
    healthInstability: "Gezondheidsinstabiliteit (CHESS)",
    otherFalls: "Andere (vallen)",
    qualityOfLifeWorsening: "Verslechtering van de kwaliteit van leven (HUI3)",
    "riskOfDecline": "Risico op gebeurtenis",
    whatIfYouDecide: "Wat als u besluit:",
    probabilityOfHospitalization: "Waarschijnlijkheid van ziekenhuisopname in de komende 12 maanden.",
    probabilityOfWorseningIADL: "Waarschijnlijkheid van een significante verslechtering in IADL in de komende 12 maanden.",
    otherFallsInfoBox: "Het individuele behandelingsresultaat (ITE) is het verschil tussen het voorspelde effect na het starten van anticholinergische medicatie en het voorspelde effect als de patiënt geen medicatie krijgt. Een voorspeld effect lager dan 0 duidt op een lagere kans op vallen na de start van medicatie in vergelijking met geen medicatie. Een hoger voorspeld effect duidt op een grotere kans op vallen met medicatie in vergelijking met geen medicatie. Tijdspanne: een terugkijkperiode van 90 dagen na 12 maanden.",
    lastAllInfoBox: "Het individuele behandelingsresultaat (ITE) is het verschil tussen het voorspelde effect onder behandeling en het voorspelde effect als de patiënt geen behandeling krijgt. Een voorspeld effect lager dan 0 wijst op minder achteruitgang, geen achteruitgang of grotere verbetering met therapie in vergelijking met geen therapie. Een hoger voorspeld effect duidt op een grotere achteruitgang met therapie in vergelijking met geen therapie."
};

export default {
    locale: "nl-BE",
    messages: {
        title: titles
    },
};
